import React, { useContext, useRef } from 'react';
import type { WCmsHomepageSectionView } from '@zola/svc-web-api-ts-client';
import { DeviceContext } from 'contexts/DeviceContext';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { Divider } from 'components/publicWebsiteV2/util/pageCustomizationOrientation.styles';
import { pickDeviceProperties } from 'components/publicWebsiteV2/util/getRelativeImgSize';
import { Container, Subtitle, Subcontainer, TitleContainer } from './Story.styles';

type StoryProps = Pick<WCmsHomepageSectionView, 'title' | 'subtitle' | 'description'> & {
  textAlignment: string;
  orientation: string;
  disableAssets?: boolean;
};

const Story = ({
  title,
  subtitle,
  description,
  textAlignment,
  orientation,
  disableAssets,
}: StoryProps): JSX.Element => {
  const {
    state: {
      components: {
        styleCmsEntityHeaderFont,
        styleCmsEntityBodyFont,
        cmsEntityComponentBodyFontValues,
        styleCmsEntityBodyFont2,
      },
      wedding,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { HOMEPAGE_STORY_HEADER } = wedding?.public_theme_v2?.components || {};
  const titleRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(titleRef);
  const H3 = styleCmsEntityHeaderFont('h3');
  const StyledSubtitle = styleCmsEntityBodyFont2(Subtitle);
  const P = styleCmsEntityBodyFont('p');

  return (
    <Container isHorizontalOrientation={orientation === 'HORIZONTAL'} textAlignment={textAlignment}>
      <TitleContainer
        inFlowAsset={disableAssets ? undefined : HOMEPAGE_STORY_HEADER?.in_flow_assets}
        device={pickDeviceProperties(device)}
        containerWidth={containerWidth}
        ref={titleRef}
      >
        <div>
          {/* Defensive styling for bootstrap styles applied in preview */}
          <H3 style={{ margin: 0 }}>{title}</H3>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </div>
      </TitleContainer>
      {orientation === 'HORIZONTAL' && (
        <Divider style={{ backgroundColor: `#${cmsEntityComponentBodyFontValues.color}` }} />
      )}
      <Subcontainer>
        <P>{description}</P>
      </Subcontainer>
    </Container>
  );
};

export default Story;
