import React, { useContext, useRef } from 'react';
import { useTheme } from '@emotion/react';
import mapFontValuesToComponent from 'components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';
import { DeviceContext } from 'contexts/DeviceContext';
import { useContainerDimensions } from '@zola/zola-ui/src/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { getResponsiveNamesFontSizeInVW } from 'components/publicWebsiteV2/pageLayouts/SinglePageLayout/helpers/responsiveFontSizeHelpers';
import { pickDeviceProperties } from 'components/publicWebsiteV2/util/getRelativeImgSize';
import { renderAbsoluteAssets } from '../../util/renderHelpers';
import {
  OuterContainer,
  Container,
  ContentContainerVertical,
  ContentContainerHorizontal,
  Name1,
  Name2,
  And,
  H2,
} from './NamesModule.styles';

type NamesModulesProps = {
  ownerFirstName?: string;
  ownerLastName?: string;
  partnerFirstName?: string;
  partnerLastName?: string;
  className?: string;
  textAlignment: string;
  // Used for single page welcomes names component
  hideNames?: boolean;
  disableAssets?: boolean;
  fontSizeOverride?: {
    desktop: string;
    tablet: string;
    mobile: string;
  };
};

/**
 *
 * Used on
 * - passcode page
 * - single page home section
 * - multi page home section
 */
const NamesModule = ({
  ownerFirstName,
  ownerLastName,
  partnerFirstName,
  partnerLastName,
  className,
  textAlignment,
  hideNames,
  disableAssets,
  fontSizeOverride,
}: NamesModulesProps): JSX.Element => {
  const {
    state: {
      components: { globalHeaderFontValues, globalBodyFontValues },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { MEDIA_QUERY } = useTheme();

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  const namesComponent = wedding?.public_theme_v2?.components?.COUPLES_NAMES;

  const StyledAnd = mapFontValuesToComponent({
    fallback: globalBodyFontValues,
    partial: namesComponent?.fonts?.BODY?.[1],
    mediaQuery: MEDIA_QUERY,
    ComponentType: And,
  });

  const { ORIENTATION, SHOW_LAST_NAME, AND_TEXT, COUPLES_NAMES_ORDER } =
    namesComponent?.option_type_values || {};
  const showLastName = SHOW_LAST_NAME?.value === 'TRUE';
  const articleText = AND_TEXT?.value === '&' ? '&' : 'and';

  const orientation = ORIENTATION?.value;

  const namesFontSize =
    namesComponent?.fonts?.HEADER?.[1]?.font_size || globalHeaderFontValues?.fontSize;
  // Max mobile font size in vw that would take up remaining space
  const namesFontSizeMobileCeil = getResponsiveNamesFontSizeInVW({
    ownerFirstName,
    ownerLastName,
    partnerFirstName,
    partnerLastName,
    remainingSpace: 100,
  });
  // Convert to px to compare
  const namesFontSizeMobileCeilInPx = (namesFontSizeMobileCeil / 100) * containerWidth;

  // Mobile font size size calculated from admin value in px
  const namesFontSizeMobileStandardInPx = Math.ceil(namesFontSize * 0.8);
  const adjustedMobileFontSize =
    namesFontSizeMobileStandardInPx > namesFontSizeMobileCeilInPx
      ? `${namesFontSizeMobileCeil}vw`
      : `${namesFontSizeMobileStandardInPx}px`;

  const defaultFontSize = {
    desktop: `${namesFontSize}px`,
    tablet: `${namesFontSize}px`,
    mobile: adjustedMobileFontSize,
  };
  // If override provided, use those values instead
  const finalFontSize = fontSizeOverride || defaultFontSize;
  const StyledH2 = mapFontValuesToComponent({
    fallback: globalHeaderFontValues,
    partial: namesComponent?.fonts?.HEADER?.[1],
    mediaQuery: MEDIA_QUERY,
    excludeFontSize: true,
    ComponentType: H2,
  });

  const renderName = (firstName?: string, lastName?: string) => {
    if (!firstName) return null;
    if (!showLastName)
      return (
        <StyledH2 fontSizeOverride={finalFontSize} inPreview={inPreview}>
          {firstName}
        </StyledH2>
      );
    if (orientation === 'VERTICAL') {
      return (
        <StyledH2 fontSizeOverride={finalFontSize} inPreview={inPreview}>
          {firstName} {lastName}
        </StyledH2>
      );
    }
    return (
      <>
        <StyledH2 fontSizeOverride={finalFontSize} inPreview={inPreview}>
          {firstName}
        </StyledH2>
        <StyledH2 fontSizeOverride={finalFontSize} inPreview={inPreview}>
          {lastName}
        </StyledH2>
      </>
    );
  };

  const OwnerName = renderName(ownerFirstName, ownerLastName);
  const PartnerName = renderName(partnerFirstName, partnerLastName);

  const Name1Content = COUPLES_NAMES_ORDER?.value === 'PARTNER_FIRST' ? PartnerName : OwnerName;
  const Name2Content = COUPLES_NAMES_ORDER?.value === 'PARTNER_FIRST' ? OwnerName : PartnerName;
  const ContentContainer =
    orientation === 'VERTICAL' ? ContentContainerVertical : ContentContainerHorizontal;

  return (
    <OuterContainer hideNames={hideNames} className={className}>
      <Container
        inFlowAsset={disableAssets ? undefined : namesComponent?.in_flow_assets}
        containerWidth={containerWidth}
        device={pickDeviceProperties(device)}
        ref={containerRef}
      >
        {!disableAssets &&
          renderAbsoluteAssets({ a: namesComponent?.absolute_assets, containerWidth, device })}
        <ContentContainer textAlignment={textAlignment}>
          <Name1>{Name1Content}</Name1>
          <StyledAnd>{articleText}</StyledAnd>
          <Name2>{Name2Content}</Name2>
        </ContentContainer>
      </Container>
    </OuterContainer>
  );
};

export default NamesModule;
